// Libraries
import dayjs from "dayjs";
import "dayjs/locale/de";
import isoWeek from "dayjs/plugin/isoWeek";
import { decodeUrlForPlace } from "~/operations/shared";
dayjs.extend(isoWeek);
dayjs.locale("de");

type TableType = "oil" | "pellets";

type CityOil = {
  name: string;
  zipcode: string;
  currentPrice: {
    modifiedAt: number;
    value: number;
  };
  lastPrice: {
    modifiedAt: number;
    value: number;
  };
  relativeChange: number;
};

type CityPellets = {
  _id: string;
  POSTLEITZAHL: string;
};

type TableHeaderData = string[];
type TableRowData = (string | number | { text: string; href: string })[][];

export const returnFormattedDate = (dateString: string, dateFormat: string) => {
  dayjs.locale("de");
  let retVal = dayjs(dateString).format(dateFormat);
  if (dateFormat === "today") retVal = dayjs().format("DD.MM.YYYY");
  if (dateFormat === "day") retVal = dayjs(dateString).format("DD");
  if (dateFormat === "weekDay") retVal = dayjs(dateString).format("dddd");
  if (dateFormat === "month") retVal = dayjs(dateString).format("MMMM");
  if (dateFormat === "year") retVal = dayjs(dateString).format("YYYY");

  return retVal;
};

export const returnPriceFormatDE = (price: number | string) => {
  if (price === null) return;
  const NumberPrice = Number(price);
  return NumberPrice.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
};

export const returPriceTrendFormatDE = (price: number | string) => {
  if (price === null) return;
  const NumberPrice = Number(price);
  if (Number(NumberPrice.toFixed(2)) < 0)
    return NumberPrice.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  if (Number(NumberPrice.toFixed(2)) === 0) return `0,00 €`;
  return `+${NumberPrice.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  })}`;
};

export const removeParagraphTag = (paragraphContent: string) => {
  if (paragraphContent === null) return;
  const PlainTextString = paragraphContent.replace(
    /(<p[^>]+?>|<p>|<\/p>)/gim,
    "",
  );
  return PlainTextString;
};

export const getPriceDifference = (price1, price2) => {
  const numericCurrentPrice = parseFloat(
    __formatPriceWithoutEuroSign(price1)
      .replace(/[^0-9.,]+/g, "")
      .replace(",", ""),
  );
  const numericLastPrice = parseFloat(
    __formatPriceWithoutEuroSign(price2)
      .replace(/[^0-9.,]+/g, "")
      .replace(",", ""),
  );
  const difference = Math.abs(numericCurrentPrice - numericLastPrice);
  return {
    difference,
    numericCurrentPrice,
    numericLastPrice,
  };
};

const __formatPriceWithoutEuroSign = (price: string): string => {
  return returnPriceFormatDE(price).replace("€", "");
};

export const getFormattedPercentage = (
  currentPrice: number,
  lastPrice: number,
) => {
  if (currentPrice == null || lastPrice == null) return "-";
  const relativeChangePercentage =
    ((currentPrice - lastPrice) / lastPrice) * 100;
  return __returnPriceTrendPercentDE(relativeChangePercentage);
};

const __returnPriceTrendPercentDE = (percentage: number | string) => {
  if (percentage === null) return;
  const NumberPrice = Number(percentage);
  if (NumberPrice <= 0)
    return `${NumberPrice.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}%`;
  return `+${NumberPrice.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}%`;
};

export const formatNumberAsPercentage = (value: number | null): string => {
  return (
    Math.abs(value ?? 0).toLocaleString("de-DE", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }) + "%"
  );
};

export const getCurrentDate = () => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear().toString().slice(-2);

  const formattedDate = `${day < 10 ? "0" + day : day}.${
    month < 10 ? "0" + month : month
  }.${year}`;
  return formattedDate;
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const replaceToUmlauts = (param: string): string => {
  return param
    .replaceAll("ae", "ä")
    .replaceAll("oe", "ö")
    .replaceAll("ue", "ü");
};

export const getTableHeaderData = (
  topCitiesData: CityOil[] | CityPellets[] | null,
  type: TableType,
): TableHeaderData | undefined => {
  if (!topCitiesData?.length) return;

  if (type === "oil") {
    const oilData = topCitiesData as CityOil[];
    return [
      "Stadt",
      `Preis am ${dayjs
        .unix(oilData[0].currentPrice.modifiedAt)
        .format("DD.MM")}`,
      `Preis am ${dayjs.unix(oilData[0].lastPrice.modifiedAt).format("DD.MM")}`,
      "Differenz",
    ];
  }

  return ["Stadt"];
};

export const formatTopCitiesTableData = (
  topCitiesData: CityOil[] | CityPellets[],
  type: TableType,
): TableRowData => {
  const FormattedData: TableRowData = [];

  topCitiesData.forEach((city) => {
    if (type === "oil") {
      const oilCity = city as CityOil;
      FormattedData.push([
        {
          text: oilCity.name,
          href: decodeUrlForPlace(
            `heizoelpreise-${oilCity.name}-${oilCity.zipcode}`,
          ),
        },
        returnPriceFormatDE(oilCity.currentPrice.value),
        returnPriceFormatDE(oilCity.lastPrice.value),
        returPriceTrendFormatDE(oilCity.relativeChange),
      ]);
    } else {
      const pelletsCity = city as CityPellets;
      FormattedData.push([
        {
          text: pelletsCity._id,
          href: decodeUrlForPlace(
            `pelletspreise-${pelletsCity._id}-${pelletsCity.POSTLEITZAHL}`,
            "pelletspreise",
          ),
        },
      ]);
    }
  });

  return FormattedData;
};
