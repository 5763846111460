import { Middleware } from "@nuxt/types";
const redirectToLowerCase: Middleware = ({ route, redirect, query }) => {
  // Wenn in der Route eine Großbuchstabe ist, dann soll auf Kleinbuchstaben weitergeleitet werden

  const regex = new RegExp("[A-Z]");
  const excludePaths = [
    "heizoelhaendler-company",
    "heizoelhaendler-company-id",
    "pelletshaendler-company",
    "pelletshaendler-company-id",
  ];
  const decodedName = decodeURIComponent(route.path);

  if (!excludePaths.includes(route.name) && regex.test(decodedName)) {
    const lowerCasePath = decodedName.toLocaleLowerCase();

    const queryString = Object.keys(query).length
      ? `?${Object.entries(query)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
      : "";
    const newUrl = `${process.env.MAIN_ESYOIL}${lowerCasePath}${queryString}`;
    redirect(301, newUrl);
  }
};
export default redirectToLowerCase;
