import { getCurrentDate } from "~/mixins/formatting";

export const GenericMetas = () => ({
  desc1: `Aktuelle Heizölpreise 24/7 in der Nähe vergleichen ✚ Heute Heizöl kaufen mit esyoil ✔️Heizölhändler in der Nähe ✔️Heizölpreise 24h aktuell ✔️Stiftung Warentest: Sehr gut!`,
  desc2:
    "Stiftung Warentest: sehr gut! ✓ N24 Testsieger ✓ Über 500.000 zufriedene Kunden ✓ Aktuelle Ölpreise ✓ Größter Vergleich - 820 Heizöl-Händler ✓",
});

export const MetaTags = () => ({
  index: {
    title: `Heizölpreise vergleichen | Heizöl 24h aktuell | Heizöl kaufen in der Nähe | esyoil`,
    meta: [
      {
        hid: "title",
        name: "title",
        content: `Heizölpreise vergleichen | Heizöl 24h aktuell | Heizöl kaufen in der Nähe | esyoil`,
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc1,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      {
        name: "keywords",
        content:
          "Heizölpreise, Heizölpreise vergleichen, Heizöl kaufen, Heizölpreise in der Nähe, Heizöl 24/7",
      },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "heating-oil-overview": {
    title: `Heizölpreise Deutschland: Günstige Heizölpreise in der Nähe vergleichen`,
    meta: [
      {
        hid: "title",
        name: "title",
        content: `Heizölpreise Deutschland: Günstige Heizölpreise in der Nähe vergleichen`,
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Heizölpreise Deutschland ⭐ Günstige Heizölpreise in der Nähe vergleichen ✔️Heizölpreise 24h aktuell ✔️Heizölhändler in Ihrer Nähe finden ✔️Stiftung Warentest: Sehr gut!",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      {
        name: "keywords",
        content: "Heizölpreise Deutschland, Heizölpreise in der Nähe",
      },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "e-peilstab-plus": {
    title: "e-Peilstab plus",
    meta: [
      { hid: "title", name: "title", content: "e-Peilstab plus" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc2,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "e-peilstab-info": {
    title:
      "Öltank Füllstandsanzeige: elektronische Öltankanzeige | Öltank Füllstandsmesser e-Peilstab plus",

    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Öltank Füllstandsanzeige: elektronische Öltankanzeige | Öltank Füllstandsmesser e-Peilstab plus",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Öltank Füllstandsanzeige mit e-Peilstab plus Öltank Füllstandsmesser ✔️elektronische Öltankanzeige ✔️Bequem Heizöl Füllstand messen ✔️Heizölkauf besser planen",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Öltank Füllstandsanzeige, elektronische Öltankanzeige, Öltank Füllstandsmesser",
      },
    ],
  },
  bestellung: {
    title:
      "Aktuelle Heizölpreise vergleichen | Jetzt günstig Heizöl in ###zipcode###, ###cityname### kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Aktuelle Heizölpreise vergleichen | Jetzt günstig Heizöl in ###zipcode###, ###cityname### kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc1,
      },
      { hid: "robots", name: "robots", content: "noindex,nofollow,noodp" },
    ],
  },
  "bestellung-generic": {
    title: "Heizöl bestellen bei esyoil",
    meta: [
      { hid: "title", name: "title", content: "Heizöl bestellen bei esyoil" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Heizölvergleich bei esyoil, einfach günstig und den eigenen Heizölanbieter sicher wechseln.",
      },
      { hid: "robots", name: "robots", content: "noindex, nofollow, noodp" },
    ],
  },
  "bestellung-pellets": {
    title: "Pellets bestellen bei esyoil",
    meta: [
      { hid: "title", name: "title", content: "Pellets bestellen bei esyoil" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Pelletsvergleich bei esyoil, einfach günstig und den eigenen Pelletsanbieter sicher wechseln.",
      },
      { hid: "robots", name: "robots", content: "noindex, nofollow, noodp" },
    ],
  },
  "state-pages": {
    title:
      "Heizölpreise ###state### aktuell | Ölpreis ###zipcode### ###state### heute vergleichen | Heizöl kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Heizölpreise ###state### aktuell | Ölpreis ###zipcode### ###state### heute vergleichen | Heizöl kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Heizölpreise ###state### aktuell ⭐ Ölpreis ###state### heute ⭐ Heizöl in ###zipcode### ###state### vergleichen & kaufen ✔️Tagesaktuelle Heizölpreise in der Nähe ✔️Günstig online bestellen ✔️Flexible Zahlarten",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Heizölpreise ###state###, Ölpreis ###state###, Heizöl ###state###, Heizölpreis ###zipcode### ###state###",
      },
    ],
  },
  "state-pages-pellets": {
    title:
      "Pelletspreise ###state### aktuell | Pelletspreise ###zipcode### ###state### heute vergleichen | Pellets kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Pelletspreise ###state### aktuell | Pelletspreise ###zipcode### ###state### heute vergleichen | Pellets kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Pelletspreise ###state### aktuell ⭐ Pelletspreise ###state### heute ⭐ Holzpellets in ###zipcode### ###state### vergleichen & kaufen ✔️Tagesaktuelle Pelletpreise in der Nähe ✔️Günstig online bestellen ✔️Flexible Zahlarten",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Pelletspreise ###state###, Pellets ###state###, Holzpellets ###zipcode### ###state###",
      },
    ],
  },
  "state-overview": {
    title: "`Bundesländer in der Übersicht",
    meta: [
      { hid: "title", name: "title", content: "Bundesländer in der Übersicht" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Bundesländer in der Übersicht. Preise auf Bundeslandebene vergleichen.",
      },
    ],
  },
  "federal-states": {
    title:
      "Heizölpreise ###category### aktuell: Ölpreis ###category### vergleichen & heute kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Heizölpreise ###category### aktuell: Ölpreis ###category### vergleichen & heute kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Heizölpreise ###category### aktuell ⭐ Ölpreis ###category### heute am ${getCurrentDate()} ⭐ Heizöl in ###category###  vergleichen & kaufen ✔️Tagesaktuelle Heizölpreise ✔️Günstig online bestellen ✔️Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Heizölpreise ###category###, Ölpreis ###category###, Heizöl ###category###",
      },
    ],
  },

  "federal-states-pellets": {
    title:
      "Pelletspreise ###category### aktuell: Pelletspreise ###category### vergleichen & heute Pellets kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Pelletspreise ###category### aktuell: Pelletspreise ###category### vergleichen & heute Pellets kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Pelletspreise ###category### aktuell ⭐ Pelletspreise ###category### heute am ${getCurrentDate()} ⭐ Pelletspreise in ###category### vergleichen & Pellets kaufen ✔️Tagesaktuelle Pelletpreise ✔️Günstig online bestellen ✔️Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content: "Pelletspreise ###category###, Holzpellets ###category###",
      },
    ],
  },
  landkreis: {
    title:
      "Heizölpreise Landkreis ###name### aktuell: Ölpreis Landkreis ###name### vergleichen & heute kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Heizölpreise Landkreis ###name### aktuell: Ölpreis Landkreis ###name### vergleichen & heute kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Heizölpreise Landkreis ###name### aktuell ⭐ Ölpreis Landkreis ###name### heute am ${getCurrentDate()} ⭐ Heizöl im Landkreis ###name### vergleichen & kaufen ✔️Tagesaktuelle Heizölpreise ✔️Günstig online bestellen ✔️Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Heizölpreise Landkreis ###name###, Ölpreis Landkreis ###name###, Heizöl Landkreis ###name###",
      },
    ],
  },

  "landkreis-pellets": {
    title:
      "Pelletspreise Landkreis ###name### aktuell: Jetzt in ###name### Pelletspreise vergleichen & Pellets kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Pelletspreise Landkreis ###name### aktuell: Jetzt in ###name### Pelletspreise vergleichen & Pellets kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Pelletspreise Landkreis ###name### aktuell ⭐ Pelletspreise Landkreis ###name### heute am ${getCurrentDate()} ⭐ Holzpellets im Landkreis ###name### vergleichen & kaufen ✔️Tagesaktuelle Pelletpreise ✔️ Günstig online bestellen ✔️Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content: "Pelletspreise ###name###, Holzpellets ###name###",
      },
    ],
  },

  "district-city": {
    title:
      "Heizölpreise kreisfreie Stadt ###name### aktuell: Ölpreis kreisfreie Stadt ###name### vergleichen & heute kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Heizölpreise kreisfreie Stadt ###name### aktuell: Ölpreis kreisfreie Stadt ###name### vergleichen & heute kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Heizölpreise kreisfreie Stadt ###name### aktuell ⭐ Ölpreis kreisfreie Stadt ###name### heute am ${getCurrentDate()} ⭐ Heizöl in kreisfreier Stadt ###name### vergleichen & kaufen ✔️Tagesaktuelle Heizölpreise ✔️Günstig online bestellen ✔️Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Heizölpreise kreisfreie Stadt ###name###, Ölpreis kreisfreie Stadt ###name###, Heizöl kreisfreie Stadt ###name###",
      },
    ],
  },

  "district-city-pellets": {
    title:
      "Pelletspreise kreisfreie Stadt ###name### aktuell: Pelletspreise kreisfreie Stadt ###name### vergleichen & heute kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Pelletspreise kreisfreie Stadt ###name### aktuell: Pelletspreise kreisfreie Stadt ###name### vergleichen & heute kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Pelletspreise kreisfreie Stadt ###name### aktuell ⭐ Pelletspreise kreisfreie Stadt ###name### heute am ${getCurrentDate()} ⭐ Pelletspreise in kreisfreier Stadt ###name### vergleichen & kaufen ✔️Tagesaktuelle Pelletspreise ✔️Günstig online bestellen ✔️Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Pelletspreise kreisfreie Stadt ###name###, Pelletspreise kreisfreie Stadt ###name###, Pelletspreise kreisfreie Stadt ###name###",
      },
    ],
  },

  city: {
    title: "Heizöl in ###name###: Jetzt vergleichen & sparen mit esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizöl in ###name###: Jetzt vergleichen & sparen mit esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Jetzt kaufen & Geld sparen! Heizöl-Angebote im Vergleich ➽ Raum ###name### & Umgebung ⏰ Tagesaktuelle Heizölpreise ✓ Günstig ✓ Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  oelpreis: {
    title:
      "Ölpreis aktuell | Rohölpreis & Ölpreisentwicklung | Brent Ölpreis heute",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Ölpreis aktuell | Rohölpreis & Ölpreisentwicklung | Brent Ölpreis heute",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Ölpreis aktuell ✚ Rohölpreis aktuell Ölpreise heute am ${getCurrentDate()} Rohöl- & Ölpreisentwicklung ✚ Ölpreis Charts Brent Rohöl Preis & Chart Heizöl Preis & Chart Gasöl Preis & Chart`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      {
        name: "keywords",
        content:
          "Ölpreis, Ölpreis aktuell, Rohölpreis, Rohölpreis aktuell, Ölpreisentwicklung",
      },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  fachbetriebe: {
    title: "Öl Tankreinigung: 300+ Tankwartung Fachbetriebe",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Öl Tankreinigung: 300+ Tankwartung Fachbetriebe",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content:
          "Öl Tankreinigung ⭐ Mehr als 300 Tankwartung Fachbetriebe in Deutschland ⭐ Hier Tankreinigung Firma in der Nähe finden und Öltank reinigen lassen!",
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "Öl Tankreinigung, Öltank reinigen",
      },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heizölpreise, heizölpreis, heizöl, heizoelpreise, heizoelpreis, heizoel, aktuelle heizölpreise, günstige Heizölpreise, heizöl preisvergleich",
      },
    ],
  },
  peiltabellen: {
    title: "Öltank Füllstand ermitteln mit Peiltabellen-Rechner",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Öltank Füllstand ermitteln mit Peiltabellen-Rechner",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content:
          "Mit Hilfe der Peiltabelle (basierend auf dem Peiltabellen-Rechner), können Sie hier den aktuellen Füllstand Ihres Heizöltanks ermitteln.",
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "Öltank Füllstand ermitteln mit Peiltabellen-Rechner",
      },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heizölpreise, heizölpreis, heizöl, heizoelpreise, heizoelpreis, heizoel, aktuelle heizölpreise, günstige Heizölpreise, heizöl preisvergleich",
      },
    ],
  },
  "t-peilstab": {
    title:
      "Öltank Füllstandsanzeige: t-Peilstab plus Fernüberwachung von Tankinhalten",
    meta: [
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "title",
        name: "title",
        content:
          "Öltank Füllstandsanzeige: t-Peilstab plus Fernüberwachung von Tankinhalten",
      },
      {
        hid: "description",
        name: "description",
        content:
          "Professionelle Öltank Füllstandsanzeige mit t-Peilstab plus ✔️Leichte Instattation ✔️Fernüberwachung ✔️Für Öltanks & Wassertanks",
      },
      {
        name: "keywords",
        content: "Öltank Füllstandsanzeige",
      },
    ],
  },
  tankreinigung: {
    title: "Öl Tankreinigung: Tipps zum Heizöltank Reinigen",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Öl Tankreinigung: Tipps zum Heizöltank Reinigen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Öl Tankreinigung ⭐ Wissenswertes zum Heizöltank Reinigen & Betreiben ✔️Vorschriften zur Heizöllagerung ✔️Betreiberpflichten für Heizöltankanlagen ✔️Hinweise für Heizöltanks",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content: "Tankreinigung, Öl Tankreinigung, Heizöltank reinigen",
      },
    ],
  },
  "customer-ratings": {
    title: "Heizölhändler Bewertungen bei esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizölhändler Bewertungen bei esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content:
          "Kunden bewerten bei esyoil Ihren Heizölhändler ⭐ Echte Meinungen, Bewertungen, Kommentare und Erfahrungsberichte zur Heizöllieferung.",
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "Heizölhändler Bewertungen",
      },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heiz&ouml;lpreise, heiz&ouml;lpreis, heiz&ouml;l, heizoelpreise, heizoelpreis, heizoel, aktuelle heiz&ouml;lpreise, g&uuml;nstige Heiz&ouml;lpreise, heiz&ouml;l preisvergleich",
      },
    ],
  },
  "co2-steuer": {
    title: "CO2-Abgabe ab 2021 - Jetzt informieren",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "CO2-Abgabe ab 2021 - Jetzt informieren",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Die CO2-Abgabe ab 2021 ► Was bedeutet das für den Heizölpreis? Alle wichtigen Informationen zum Klimaschutzprogramm von esyoil",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "CO2-Steuer, CO2-Abgabe" },
    ],
  },
  faq: {
    title: "esyoil Hilfe & häufige Fragen (FAQ)",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "esyoil Hilfe & häufige Fragen (FAQ)",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "esyoil Hilfe und Fragen von Usern ⭐ Wir beantworten häufig gestelle Fragen ✔️Vor der Heizöl-Bestellung ✔️Nach der Heizöl-Bestellung,",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Hilfe, häufige Fragen, FAQ" },
    ],
  },
  "heizoel-sorten": {
    title: "Alle Heizöl-Sorten im Überblick - esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Alle Heizöl-Sorten im Überblick - esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Alle Heizöl-Sorten im Überblick ✔️Normal Schwefelarm ✔️Premium Schwefelarm ✔️CO2-Kompensiert Normal ✔️CO2-Kompensiert Premium ✔️Bio 10 ✔️Bio 15 ✔️Bio 10 Premium",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Heizöl-Sorten, Normal Schwefelarm, Premium Schwefelarm, Bio 10, Bio 15",
      },
    ],
  },
  "quality-control": {
    title:
      "TÜV zertifiziertes Qualitätsmanagement bei esyoil – ein Dienst der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "TÜV zertifiziertes Qualitätsmanagement bei esyoil – ein Dienst der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Qualitätsmanagement nach ISO 9001 bei der agital.online GmbH ✅ Zertifiziert durch den TÜV Nord für alle Dienste des Heizöl Preisvergleichs",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Qualitätsmanagement" },
    ],
  },
  ratenkauf: {
    title: "heizöl Ratenkauf: Bequem Heizöl auf Raten kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "heizöl Ratenkauf: Bequem Heizöl auf Raten kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Heizöl Ratenkauf ⭐ Zahlen Sie Ihr Heizöl sicher & bequem in 3, 6, 9, 12, 18 oder 24 monatlichen Raten ⭐ Mit dem Heizöl-Ratenkauf finanziell flexibel bleiben!",
      },
      {
        name: "keywords",
        content: "Heizöl Ratenkauf, Heizöl auf Raten kaufen",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  hvo: {
    title: "HVO-Heizöl - Die Zukunft Ihrer Ölheizung",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "HVO-Heizöl - Die Zukunft Ihrer Ölheizung",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Wichtige Informationen zu HVO-Heizöl (Hydrotreated Vegetable Oil) und wie es zur Zukunft der Ölheizung beträgt.",
      },
      { name: "keywords", content: "HVO-Heizöl, Ölheizung" },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  pellets: {
    title: "Aktuelle Pelletspreise vergleichen und günstig Pellets kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Aktuelle Pelletspreise vergleichen und günstig Pellets kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Aktuelle Pelletspreise in der Nähe vergleichen ✚ Pellets kaufen ✔️ Günstige Holzpellets gibt es bei esyoil.",
      },
      {
        name: "keywords",
        content: "Pelletspreise, Pelletheizung, Pellets, Holzpellets",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  news: {
    title: "###title###",
    meta: [
      { hid: "title", name: "title", content: "###title###" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "description", name: "description", content: "###description###" },
      { hid: "url", name: "url", content: "###url###" },
      { hid: "page-topic", name: "page-topic", content: "###title###" },
      { hid: "keywords", name: "keywords", content: "###title###" },
      { hid: "robots", name: "robots", content: "###robots###" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "news-charts": {
    title: "Heizöl Chart + Ölpreis Chart aktuell (Heizölpreis Chart)",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizöl Chart + Ölpreis Chart aktuell (Heizölpreis Chart)",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heiz&ouml;lpreise, heiz&ouml;lpreis, heiz&ouml;l, heizoelpreise, heizoelpreis, heizoel, aktuelle heiz&ouml;lpreise, g&uuml;nstige Heiz&ouml;lpreise, heiz&ouml;l preisvergleich",
      },
      {
        hid: "description",
        name: "description",
        content:
          "Entwicklung der Ölpreise im Chart: Aktuelle Heizölpreis Charts auf einen Blick ✔️Brent Rohöl Chart ✔️Gasöl Chart ✔️Dollar Euro Chart zur Umrechung der Ölpreise in Euro",
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "Ölpreis Chart, Heizöl Chart, Heizölpreis Chart",
      },
    ],
  },
  "news-overview": {
    title: "###title###",
    meta: [
      { hid: "description", name: "description", content: "###description###" },
      { hid: "title", name: "title", content: "###title###" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "Ölpreis wird weiter fallen, aktuelle Nachrichten Ölpreis",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "news-category": {
    title: "###title###",
    meta: [
      { hid: "title", name: "title", content: "###title###" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "description", name: "description", content: "###value###" },
      {
        hid: "keywords",
        name: "keywords",
        content: "###keywords###",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "news-archive": {
    title: "Heizöl News Archiv",
    meta: [
      { hid: "title", name: "title", content: "Heizöl News Archiv" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "###keywords###",
      },
      {
        hid: "description",
        name: "description",
        content: "Liste der 15 neuesten Kommentare",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "dealer-overview": {
    title:
      "Heizölhändler in Deutschland: Beste Heizöllieferanten in der Nähe finden",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Heizölhändler in Deutschland: Beste Heizöllieferanten in der Nähe finden",
      },
      {
        hid: "description",
        name: "description",
        content:
          "Heizölhändler in Deutschland ⭐ Die besten Heizöllieferanten in der Nähe finden ⭐ Günstig Heizöl kaufen beim Händler vor Ort ✔️Heizölhändler mit Bewertungen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "robots",
        name: "robots",
        content: "index,follow,noodp",
      },
      {
        hid: "keywords",
        name: "keywords",
        content:
          "Heizölhändler, Heizöllieferanten, Heizölhändler in der Nähe, Heizöllieferanten in der Nähe",
      },
    ],
  },
  "dealer-overview-state": {
    title:
      "Heizölhändler in ###state###: Beste Heizöllieferanten in der Nähe finden",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Heizölhändler in ###state###: Beste Heizöllieferanten in der Nähe finden",
      },
      {
        hid: "description",
        name: "description",
        content:
          "Heizölhändler in ###state### ⭐ Die besten Heizöllieferanten in der Nähe finden ⭐ Günstig Heizöl kaufen beim Händler vor Ort ✔️Heizölhändler mit Bewertungen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "robots",
        name: "robots",
        content: "index,follow,noodp",
      },
      {
        hid: "keywords",
        name: "keywords",
        content:
          "Heizölhändler in ###state###, Heizöllieferanten in ###state###, Heizölhändler in der Nähe, Heizöllieferanten in der Nähe",
      },
    ],
  },
  "pellets-dealer-overview": {
    title:
      "Pelletshändler in Deutschland: Beste Pelletslieferanten in der Nähe finden",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Pelletshändler in Deutschland: Beste Pelletslieferanten in der Nähe finden",
      },
      {
        hid: "description",
        name: "description",
        content:
          "Pelletshändler in Deutschland ⭐ Die besten Pelletslieferanten in der Nähe finden ⭐ Günstig Pellets kaufen beim Händler vor Ort ✔️Pelletshändler mit Bewertungen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "robots",
        name: "robots",
        content: "index,follow,noodp",
      },
      {
        hid: "keywords",
        name: "keywords",
        content:
          "Pelletshändler, Pelletslieferanten, Pelletshändler in der Nähe, Pelletslieferanten in der Nähe",
      },
    ],
  },
  "pellets-dealer-overview-state": {
    title:
      "Pelletshändler in ###state###: Beste Pelletslieferanten in der Nähe finden",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Pelletshändler in ###state###: Beste Pelletslieferanten in der Nähe finden",
      },
      {
        hid: "description",
        name: "description",
        content:
          "Pelletshändler in ###state### ⭐ Die besten Pelletslieferanten in der Nähe finden ⭐ Günstig Pellets kaufen beim Händler vor Ort ✔️Pelletshändler mit Bewertungen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "robots",
        name: "robots",
        content: "index,follow,noodp",
      },
      {
        hid: "keywords",
        name: "keywords",
        content:
          "Pelletshändler in ###state###, Pelletslieferanten in ###state###, Pelletshändler in der Nähe, Pelletslieferanten in der Nähe",
      },
    ],
  },
  partner: {
    title: "###title###",
    meta: [
      { hid: "title", name: "title", content: "###title###" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "description", name: "description", content: "###content###" },
      {
        hid: "robots",
        name: "robots",
        content: "###robots###",
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "###keywords###",
      },
    ],
  },
  "partner-list": {
    title: `Heizöl-Händlerliste: ###letter###`,
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizöl-Händlerliste: ###letter###",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content: `Heizöl-Händlerliste der mit ###letter### anfangenden Heizöl-Händler und deren Bewertungen auf esyoil`,
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "Heizöl-Händler, Heizöl-Händlerliste, Heizölpreise, Heizöl",
      },
    ],
  },
  "pellets-partner-list": {
    title: `Pellets-Händlerliste: ###letter###`,
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Pellets-Händlerliste: ###letter###",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content: `Pellets-Händlerliste der mit ###letter### anfangenden Pellets-Händler und deren Bewertungen auf esyoil`,
      },
      {
        hid: "keywords",
        name: "keywords",
        content:
          "Pellets-Händler, Pellets-Händlerliste, Pelletspreise, Pellets",
      },
    ],
  },
  app: {
    title: "Heizölpreise App downloaden & los sparen » esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizölpreise App downloaden & los sparen » esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Jetzt die Heizölpreise App von esyoil downloaden & immer tagesaktuell informiert sein! Heizölpreise vergleichen ✅ Heizöl bestellen ✅ Regionale Händler finden ✅ Mobil für Android oder iOS Geräte ✅ Kostenloser Download im Apple App oder Google Play Store ▶ Jetzt runterladen und los sparen!",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Heizölpreise App, Preisvergleich-App" },
    ],
  },
  "heizoel-lieferung": {
    title: "Tipps rund um die Heizöllieferung: Das sollten Sie beachten!",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Tipps rund um die Heizöllieferung: Das sollten Sie beachten!",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Die nächste Heizöllieferung steht bei Ihnen an? Wir zeigen Ihnen, worauf Sie für einen reibungslosen Ablauf achten sollten.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content: "Heizöllieferung, Heizöllieferung Checkliste",
      },
    ],
  },
  "heizoel-verbrauch": {
    title: "Heizölverbrauch berechnen & senken (Heizölrechner)",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizölverbrauch berechnen & senken (Heizölrechner)",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Einfach Heizölverbrauch berechnen mit dem esyoil Heizölrechner ✔️Heizöl-Jahresverbrauch ermitteln ✔️Heizölverbrauch optimieren ✔️Tipps zum Energie Sparen",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content: "Herzölverbrauch, Heizölverbrauch berechnen, Heizölrechner",
      },
    ],
  },
  "historien-rechner": {
    title: "Heizölpreis-Historie: 15-jähriges Heizölpreis-Archiv",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizölpreis-Historie: 15-jähriges Heizölpreis-Archiv",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content:
          "Heizölpreis-Historie für Ihre Postleitzahl: Mit dem Historien-Preisrechner können Sie das esyoil Heizölpreis-Archiv nach den Preisen der letzten 15 Jahre durchsuchen.",
      },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heiz&ouml;lpreise, heiz&ouml;lpreis, heiz&ouml;l, heizoelpreise, heizoelpreis, heizoel, aktuelle heiz&ouml;lpreise, g&uuml;nstige Heiz&ouml;lpreise, heiz&ouml;l preisvergleich",
      },
      { name: "keywords", content: "Heizölpreis-Historie, Heizölpreis-Archiv" },
    ],
  },
  oelheizung: {
    title: "Ölheizung 2025: Neue Ölheizung kaufen (Öl Brennwertheizung)",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Ölheizung 2025: Neue Ölheizung kaufen (Öl Brennwertheizung)",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Planen Sie 2025 den Heizungsanlagen-Neukauf oder -Austausch? Eine moderne neue Ölheizung (Öl Brennwertheizung) ist effektiv, sparsam & zuverlässig.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Ölheizung, Öl Brennwertheizung, Ölheizung kaufen, neue Ölheizung, Ölheizung Kosten",
      },
    ],
  },
  "tips-and-tricks": {
    title: "Spartipps & Spartricks beim Heizölkauf",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Spartipps & Spartricks beim Heizölkauf",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Sparen Sie jetzt beim Heizölkauf ⭐ Tipps und Tricks für einen günstigen Heizölkauf ⭐ Zusammengestellt vom Heizöl-Preisvergleich esyoil.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content: "Heizölkauf, Heizöl kaufen",
      },
    ],
  },
  agb: {
    title:
      "Allgemeine Geschäfts- und Nutzungsbedingungen der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Allgemeine Geschäfts- und Nutzungsbedingungen der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Unsere Geschäfts-und Nutzungsbedingungen informieren Sie über Widerrufsrecht, Haftung, Zahlungs-und Lieferbedingungen sowie weitere Vertragsregelungen.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Agb" },
    ],
  },
  datenschutz: {
    title: "Datenschutz der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Datenschutz der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: "Datenschutz der agital.online GmbH",
      },
      { name: "keywords", content: "Datenschutz" },
    ],
  },
  impressum: {
    title: "Impressum agital.online GmbH",
    meta: [
      { hid: "title", name: "title", content: "Impressum agital.online GmbH" },
      {
        hid: "description",
        name: "description",
        content:
          "Wir informieren Sie über die Kontaktmöglichkeiten des Unternehmens, die Registereinträge und die vertretungsberechtigten Personen der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,nofollow,noodp" },
      { name: "keywords", content: "Impressum" },
    ],
  },
  presse: {
    title: "Presse und Publikationen der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Presse und Publikationen der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Wir bieten umfassende Informationen rund um Heizöl. Kontaktieren Sie uns zu bestimmten Medien oder informieren Sie sich in unserem Pressearchiv.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Presse, Download" },
    ],
  },
  esyoil: {
    title: "agital.online GmbH - Das Unternehmen",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "agital.online GmbH - Das Unternehmen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Unsere Mitarbeiter sind einzigartig und unser Unternehmen ist es auch! Wir sind Pioniere im Preisvergleich für Heizöl und digitalisieren den Heizölmarkt...",
      },
      { name: "keywords", content: "esyoil Historie" },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  unternehmen: {
    title: "agital.online GmbH - Das Unternehmen",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "agital.online GmbH - Das Unternehmen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Unsere Mitarbeiter sind einzigartig und unser Unternehmen ist es auch! Wir sind Pioniere im Preisvergleich für Heizöl und digitalisieren den Heizölmarkt...",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  team: {
    title: "Das esyoil Team - Unsere Mitarbeiter",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Das esyoil Team - Unsere Mitarbeiter",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Alle esyoil Mitarbeiter auf einen Blick. Werde Teil unseres Teams und bewirb Dich jetzt direkt oder initiativ.",
      },
      { hid: "robots", name: "robots", content: "noindex,follow,noodp" },
      { name: "keywords", content: "esyoil Team" },
    ],
  },
  unternehmer: {
    title: "esyoil - Der Unternehmer Klaus Bergmann",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "esyoil - Der Unternehmer Klaus Bergmann",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Der Unternehmer Klaus Bergmann ist das Gesicht von esyoil und gründete das Unternehmen bereits im Jahr 2002.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  jobs: {
    title: "###position### - Karriere bei esyoil",
    meta: [
      { hid: "title", name: "title", content: "Karriere bei esyoil" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Ein Job als ###position### bei der agital.online GmbH`,
      },
    ],
  },
  "jobs-all": {
    title: "Jobs bei esyoil in Lüneburg | Karriere bei der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Jobs bei esyoil in Lüneburg | Karriere bei der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: "Esyoil - Job & Karriere ✅ Jetzt informieren & bewerben!",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  betriebsanleitungen: {
    title: "e-Peilstab plus & e-Peilstab H2O Bedienungsanleitung",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "e-Peilstab plus & e-Peilstab H2O Bedienungsanleitung",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "e-Peilstab plus Bedienungsanleitung und e-Peilstab H2O Bedienungsanleitung als PDF zum Download ✔️Hier herunterladen",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "e-Peilstab plus Bedienungsanleitung, e-Peilstab H2O Bedienungsanleitung",
      },
    ],
  },
});
