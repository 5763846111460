import { Middleware } from "@nuxt/types";

const legacyDealerSlug: Middleware = ({ route, error }) => {
  // check if the slug in the url is valid. for that, we seperate out the .php (if there) and take the last part exploded at "-"
  const slug = route.fullPath.split("-").slice(-1)[0].split(".")[0];

  // throw out if slug is shorter or equal to 4 digits
  if (Number(slug) && slug.length <= 4) {
    // this is an old page. let's return gone status code
    error({
      statusCode: 410,
      message: "Die Seite konnte nicht mehr gefunden werden.",
    });
  }
};

export default legacyDealerSlug;
